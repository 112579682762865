import { Farm } from '@core/models/farm.model';
import { Entity } from '@core/enums/entity.enum';

export class SetActiveFarm {
  public static readonly type = '[General] Set active farm';

  constructor(public farm: Farm) {}
}

export class SetEntityListFilters {
  public static readonly type = '[General] Set list filters of entity';

  constructor(
    public entity: Entity | string,
    public filters: Record<string, any>,
  ) {}
}

export class SetShippingDate {
  public static readonly type = '[General] Set shipping date';

  constructor(public shippingDate: string) {}
}

export class SetShipment {
  public static readonly type = '[General] Set shipment';

  constructor(public shipment: string) {}
}
