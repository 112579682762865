import { Action, State, StateContext } from '@ngxs/store';
import { GeneralStateModel } from '@store/admin/general/general-state.model';
import {
  SetActiveFarm,
  SetEntityListFilters,
  SetShipment,
  SetShippingDate,
} from '@store/admin/general/general.actions';
import { Injectable } from '@angular/core';

@State<GeneralStateModel>({
  name: 'general',
})
@Injectable()
export class GeneralState {
  @Action(SetActiveFarm)
  public setActiveFarm(ctx: StateContext<GeneralStateModel>, { farm }: SetActiveFarm): void {
    const state = ctx.getState();

    ctx.setState({
      ...state,
      farm,
    });
  }

  @Action(SetEntityListFilters)
  public setFilters(ctx: StateContext<GeneralStateModel>, { entity, filters }: SetEntityListFilters): void {
    const state = ctx.getState();
    const key = `${entity}-list-filters`;
    const adjustedFilters = { ...state.filters, [key]: filters };

    ctx.setState({
      ...state,
      filters: adjustedFilters,
    });
  }

  @Action(SetShippingDate)
  public setShippingDate(ctx: StateContext<GeneralStateModel>, { shippingDate }: SetShippingDate): void {
    const state = ctx.getState();

    ctx.setState({
      ...state,
      shippingDate: shippingDate || null,
    });
  }

  @Action(SetShipment)
  public setShipment(ctx: StateContext<GeneralStateModel>, { shipment }: SetShipment): void {
    const state = ctx.getState();

    ctx.setState({
      ...state,
      shipment,
    });
  }
}
