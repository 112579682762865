export enum EntityAction {
  create = 'create',
  read = 'read',
  update = 'update',
  delete = 'delete',
  add = 'add',
  download = 'download',
  unplan = 'unplan',
  activated = 'activated',
  deactivated = 'deactivated',
}
