import { Selector } from '@ngxs/store';
import { GeneralState } from '@store/admin/general/general.state';
import { GeneralStateModel } from '@store/admin/general/general-state.model';
import { Farm } from '@core/models/farm.model';

export class GeneralSelectors {
  @Selector([GeneralState])
  public static getActiveFarm(state: GeneralStateModel): Farm {
    return state && state.farm;
  }

  @Selector([GeneralState])
  public static getEntityListFilters(entity: string): any {
    const key = `${entity}-list-filters`;

    return ({ general }) => {
      return (general?.filters && general.filters[key]) || {};
    };
  }

  @Selector([GeneralState])
  public static getShippingDate(state: GeneralStateModel): string {
    return state.shippingDate;
  }

  @Selector([GeneralState])
  public static getShipment(state: GeneralStateModel): string {
    return state.shipment;
  }
}
